.product-title {
  /* color: var(--bs-primary); */
  /* font-size: 1.2rem; */
  /* font-weight: 600; */
  text-align: center;
}

.product-title-div {
  display: flex;
  justify-content: center;
}

.product-card {
    background-color: var(--bs-light);
    border: 1px solid var(--light-grey);
    border-radius: 8px;
    height: 100%;
    display: flex;
    object-fit: scale-down;
}

.product-card-div {
  display: center;
  justify-content: center;
}

.product-image {
    height: 300px;
    width: 300px;
    object-fit: scale-down;
    margin-bottom: 20px;
    margin-top: 9px;
    display: flex;
}

.product-image-div {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.product-price {
    color: black;
    font-size: medium;
    font-weight: bold;
    display: flex;
  }

  .product-price-div {
    display: flex;
    justify-content: center;
  }

.product-description {
    color: black;
    font-size: medium;
    font-weight: lighter;
    display: flex;
    width: 300px;
    text-align: center;
}

.product-description-div {
  display: flex;
  justify-content: center;
}

.button-div {
  width: 100%;
  display: flex;
  justify-content: center;
}