.cart-icon {
  font-size: 1.8rem;
}

.floating-buttons .cart {
  color: white;
  padding-bottom: 10px;
  padding-top: 15px;
  transition: 0.3s;
}

.navbar .cart {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}

.navbar .cart:hover {
  background-color: var(--bs-danger) !important;
  border-color: var(--bs-danger) !important;
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}
