.sale-container {

  /* background-color: rgb(var(--bs-primary-rgb), 0.1); */
  background-color: var(--bs-primary);
  color: var(--bs-light);
  min-height: 650px;
  text-align: center;
}

.sale-title {
  color: var(--bs-light);
}
