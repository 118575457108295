/* stylelint-disable selector-no-qualifying-type */

/* stylelint-disable max-line-length */

/* stylelint-disable selector-max-specificity */
body {
  --bs-primary: #fc553d;
  --bs-primary-light: #fcf4f3;
  --bs-primary-rgb: 252 , 85 , 61;
  --bs-secondary: #007aff;
  --bs-secondary-light: #eef6fe;
  --bs-secondary-rgb: 0 , 122 , 255;
  --bs-light: #fff;
  --light-grey: #e4e9f0;
  --half-gray: #f5f5f5;
  --bs-gray: #585e62;
  --bs-btn-hover-bg: #0b5ed7;
  --gray-500: #adb5bd;
  color: var(--bs-gray) !important;
  font-family: Varela Round , sans-serif !important;
  margin: 0;
  padding: 0;
  width: 100% !important;
}

h1 {
  color: var(--bs-primary);
  font-size: 2.5rem;
}

h3 {
  font-family: Varela Round , sans-serif !important;
  font-size: 1.3rem;
}

h4 {
  color: var(--gray-500);
  font-size: 1rem;
  font-weight: 600;
}

.section-title h1 {
  font-family: Rubik Gemstones , cursive !important;
}

p {
  color: var(--bs-gray);
  font-size: 0.95rem !important;
  margin: 0;
}

#root {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

header {
  width: 100%;
}

main {
  height: 100%;
  width: 100%;
}

footer {
  width: 100%;
}

.form-control {
  text-align: start !important;
}

.link {
  color: var(--bs-light) !important;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-decoration: none;
}

.bg-primary {
  background-color: var(--bs-primary) !important;
}

.bg-secondary {
  background-color: var(--bs-secondary) !important;
}

.btn-danger {
  background-color: #fc553d;
  border-color: #fc553d;
  color: #fff;
}

.btn-danger:hover {
  background-color: #d84b36;
  border-color: #d84b36;
  color: #fff;
}

.btn-secondary:active, .btn-secondary:focus, .btn-check:focus + .btn-secondary, .btn-check:active + .btn-secondary, .btn-check:checked + .btn-secondary, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  background-color: #fc553d;
  border-color: #fc553d;
  color: #fff;
}
