.floating-buttons {
  bottom: 20px; /* Place the button at the bottom of the page */
  position: fixed; /* Fixed/sticky position */
  right: 15px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
}

.floating-buttons .scroll-to-top {
  color: white; /* Hidden by default */
  display: none;
  font-size: 1.8rem; /* Increase font size */
  transition: 0.3s;
}

.floating-buttons .checkout {
  color: white;
  display: none;
  font-size: 1.8rem;
  transition: 0.3s;
}
