.animal-img-icon {
  margin-right: 3px;
  padding: 2px;
}

.btn-category {
  border-radius: 0;
  height: 4rem;
}

.nav-categories .toggle, [ id ^= 'drop' ] {
  display: none;
}

.nav-categories {
  border-bottom: var(--bs-secondary) 4px solid;
  border-top: var(--bs-secondary) 4px solid;
  margin: 0;
  padding: 0;
}

.nav-categories:after {
  clear: both;
  content: '';
  display: table;
}

.nav-categories ul {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

.nav-categories ul li {
  display: inline-block;
  float: left;
  margin: 0;
}

.nav-categories a {
  color: #fff;
  display: block;
  font-size: 20px;
  line-height: 60px;
  padding: 0 20px;
  text-decoration: none;
}

/* .nav-categories ul li ul li {
  background: var(--bs-primary);
} */

.nav-categories ul li ul li:hover { background: var(--bs-primary); }

.nav-categories a:hover {
  background-color: var(--bs-primary);
  color: #fff;
}

.nav-categories ul ul {
  border: var(--bs-secondary) 4px solid;
  display: none;
  position: absolute;
  top: 60px;
  z-index: 100;
}

.nav-categories ul li:hover > ul { display: inherit; }

.nav-categories ul ul li {
  background-color: var(--bs-secondary);
  display: list-item;
  float: none;
  position: relative;
}

/* .nav-categories ul ul ul li {
  left: 170px;
  position: relative;
  top: -60px;
} */

/* li > a:after { content: ' +'; } */

/* li > a:only-child:after { content: ''; } */


/* Media Queries
--------------------------------------------- */

@media all and ( max-width : 768px ) {

  .nav-categories { margin: 0; }

  .nav-categories .toggle + a, .menu { display: none; }

  .nav-categories .toggle {
    background-color: var(--bs-secondary);
    border: none;
    color: #fff;
    display: block;
    font-size: 22px;
    font-weight: bold;
    line-height: 60px;
    padding: 0 20px;
    text-decoration: none;
  }

  .nav-categories ul .toggle:hover { background-color: var(--bs-primary); }

  .nav-categories [ id ^= 'drop' ]:checked + ul { display: block; }

  .nav-categories ul li {
    display: block;
    width: 100%;
  }

  .nav-categories ul ul .toggle, .nav-categories ul ul a { padding: 0 40px; }

  .nav-categories ul ul ul a { padding: 0 80px; }

  .nav-categories a:hover, nav ul ul ul a { background-color: var(--bs-secondary); }

  .nav-categories ul li ul li .toggle, nav ul ul a {
    background-color: var(--bs-secondary);
  }

  .nav-categories ul ul {
    border: var(--bs-primary) 4px solid;
    color: #fff;
    float: none;
    position: static;
  }

  .nav-categories ul ul li:hover > ul, nav ul li:hover > ul { display: none; }

  .nav-categories ul ul li {
    display: block;
    width: 100%;
  }

  .nav-categories ul ul ul li { position: static;

  }
}

@media all and ( max-width : 330px ) {

  .nav-categories ul li {
    display: block;
    width: 94%;
  }

}

