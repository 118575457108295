.navbarGlobal {
  margin: 1em auto;
  text-align: center;
}

.cart-number-container {
  background-color: var(--bs-light);
  border-radius: 50%;
  height: 22px;
  width: 22px;
}

.login-icon {
  font-size: 1.4rem;
}

.login-links {
  display: inline-block;
  font-size: 0.7rem;
  line-height: 0.8rem;
}

.btn-outline-danger {
  border-color: var(--bs-light) !important;
  color: var(--bs-light) !important;
}

.btn-outline-danger:hover {
  border-color: var(--bs-danger) !important;

  /* color: var(--bs-light) !important; */
}

.search {
  padding-left: 12px !important;
}
