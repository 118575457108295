/* stylelint-disable selector-no-qualifying-type */

/* stylelint-disable selector-max-specificity */

/* stylelint-disable max-line-length */
.payment-form {
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 10px;
}

.form-payment {
  margin: auto;
  max-width: 600px;
}

.checkout .form-payment {
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.075);
  margin: auto;
  max-width: 600px;
}

.checkout .title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 0.8em;
  padding-bottom: 8px;
}

.checkout .products .item {
  margin-bottom: 1em;
}

.checkout .products .item p {
  margin-bottom: 0.2em;
}

.checkout .products .price {
  float: right;
  font-weight: 600;
}

.checkout .products .total {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 500;
  line-height: 1;
  margin-top: 10px;
  padding-top: 19px;
}

.checkout a, .payment-form a:not( [ href ] ) {
  color: grey;
  cursor: pointer;
  font-size: 13px;
  margin: 0;
  padding: 0;
}


@media ( min-width : 576px ) {

  .checkout .title {
    font-size: 1.2em;
  }

  .checkout .products .item-name {
    font-size: 1em;
  }

  .checkout .products .price {
    font-size: 1em;
  }
}

.wrapper-2NL_1P.svelte-1b0nnpf.svelte-1b0nnpf {
  height: initial !important;
  width: initial !important;
}

.wrapper-2NL_1P.svelte-1b0nnpf.svelte-1b0nnpf svg {
  max-height: 20px;
  max-width: 30px;
}

button.svelte-1a8kh4a {
  background-color: var(--bs-secondary) !important;
  background-image: none !important;
}

button.secondary-3jBDpq.svelte-1a8kh4a {
  color: white !important;
}

button.svelte-1a8kh4a:hover {
  background-color: var(--bs-btn-hover-bg) !important;
}

.row-UZRYUT.svelte-rurvvw {
  align-items: end;
}
