.quantity {
  color: var(--bs-gray) !important;
  font-size: 0.9rem !important;
  margin: 0 !important;

  /* min-width: 50px !important; */
  text-align: center !important;
}

.button-quantity-group {
  font-size: 1.3rem !important;

  /* height: auto; */
  padding: 0 13px !important;
}

.quantity-group {
  min-width: 120px !important;
}

@media only screen and ( max-width : 992px ) {

  .actions {
    max-width: 200px;
  }
}

